<template>
  <b-container>
    <b-modal
      id="modal-delete-danger"
      ok
      cancel
      ok-variant="danger"
      ok-title="Accept"
      modal-class="modal-danger"
      centered
      title="Delete Coupon"
      @ok="deleteCoupon()"
    >
      <b-card-text> Are you sure you want to delete this Coupon? </b-card-text>
    </b-modal>
    <div class="card" v-if="!isLoading">
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="m-1">Coupons</h1>
        <div>
          <b-button
            class="mr-1"
            variant="outline-success"
            to="/pages/add-coupon"
          >
            Add Coupon
          </b-button>
        </div>
      </div>
      <b-table
        striped
        hover
        responsive
        class="position-relative mb-0"
        :items="coupons"
        :fields="fields"
      >
        <template #cell(expiration)="data">
          <span v-if="data.value">
            {{ formattedDate(data.value) }}
          </span>
        </template>
        <template #cell(number_of_reduction)="data">
          <span v-if="data.value"> {{ data.value }} % </span>
        </template>
        <template #cell(action)="data">
          <b-button
            variant="flat-danger"
            class="btn-icon rounded-circle"
            title="Delete"
            @click="setDeleteId(data.item.id)"
          >
            <feather-icon icon="Trash2Icon" size="20" />
          </b-button>
        </template>
      </b-table>
    </div>
    <LoaderVue v-else />
  </b-container>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import LoaderVue from "@/components/Loader/Loader.vue";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormDatepicker,
  BFormCheckbox,
  BSpinner,
  VBTooltip,
  BModal,
  BCardText,
  BContainer,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
    BContainer,
    VBTooltip,
    BModal,
    BCardText,
    LoaderVue,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      /*
        'type_coupon',
        'coupon',
        'expiration',
        'number_limit',
        'number_of_reduction',
      
      
      */
      fields: [
        {
          key: "index",
          label: "#",
          class: "text-center",
        },
        {
          key: "coupon",
          label: "coupon",
          class: "text-center",
        },
        {
          key: "type_coupon",
          label: "coupon type",
          class: "text-center",
        },
        {
          key: "expiration",
          label: "coupon expiration",
          class: "text-center",
        },
        {
          key: "number_limit",
          label: "Limit",
          class: "text-center",
        },
        {
          key: "number_of_reduction",
          label: "reduction",
          class: "text-center",
        },
        {
          key: "action",
          label: "action",
          class: "text-center",
        },
      ],
      coupons: [],
      isLoading: false,
      selected: 0,
      options: [
        { value: 0, text: "Not Paid" },
        { value: 1, text: "Paid" },
      ],
      beDeleted: null,
    };
  },
  mounted() {
    this.getCoupons();
  },
  methods: {
    formattedDate(dateStr) {
      const dt = moment(dateStr);
      return dt.format("DD-MM-YYYY");
    },
    getCoupons() {
      this.isLoading = true;
      axios
        .get("/coupons")
        .then(({ data }) => {
          this.coupons = this.addIndexToTable(data.coupons);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },
    deleteCoupon() {
      axios
        .delete(`/coupons/${this.beDeleted}`)
        .then(({ data }) => {
          this.showToast(
            "success",
            "CheckIcon",
            "Success",
            "Coupon Deleted Successfully !"
          );
          this.getCoupons();
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something Wrong !"
          );
        });
    },
    addIndexToTable(table) {
      const t = table.map((item, index) => {
        item.index = index + 1;
        return item;
      });
      return t;
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    setDeleteId(id) {
      this.beDeleted = id;
      this.$root.$emit("bv::show::modal", "modal-delete-danger");
    },
  },
  beforeRouteEnter(to, from, next) {
    axios
      .post("/isAdmin")
      .then(({ data }) => {
        if (data.status === true) {
          next(true);
        } else if (data.status === false) {
          next("/error-404");
        }
      })
      .catch((error) => {
        next("/login");
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
